export const topCities = [
  {
    "id": "berlin",
    "city": "Berlin",
    "population": "3.669.491",
    "geo": [
      13.38333,
      52.51667
    ]
  },
  {
    "id": "hamburg",
    "city": "Hamburg",
    "population": "1.847.253",
    "geo": [
      10,
      53.55
    ]
  },
  {
    "id": "muenchen",
    "city": "München",
    "population": "1.484.226",
    "geo": [
      11.56667,
      48.13333
    ]
  },
  {
    "id": "koeln",
    "city": "Köln",
    "population": "1.087.863",
    "geo": [
      6.95778,
      50.94222
    ]
  },
  {
    "id": "frankfurt_am_main",
    "city": "Frankfurt am Main",
    "population": "763.380",
    "geo": [
      8.67972,
      50.11361
    ]
  },
  {
    "id": "stuttgart",
    "city": "Stuttgart",
    "population": "635.911",
    "geo": [
      9.1775,
      48.77611
    ]
  },
  {
    "id": "duesseldorf",
    "city": "Düsseldorf",
    "population": "621.877",
    "geo": [
      6.81667,
      51.21667
    ]
  },
  {
    "id": "leipzig",
    "city": "Leipzig",
    "population": "593.145",
    "geo": [
      12.38333,
      51.33333
    ]
  },
  {
    "id": "dortmund",
    "city": "Dortmund",
    "population": "588.250",
    "geo": [
      7.46528,
      51.51389
    ]
  },
  {
    "id": "essen",
    "city": "Essen",
    "population": "582.760",
    "geo": [
      7.01476,
      51.45807
    ]
  },
  {
    "id": "bremen",
    "city": "Bremen",
    "population": "567.559",
    "geo": [
      8.80889,
      53.07694
    ]
  },
  {
    "id": "dresden",
    "city": "Dresden",
    "population": "556.780",
    "geo": [
      13.75,
      51.05
    ]
  },
  {
    "id": "hannover",
    "city": "Hannover",
    "population": "536.925",
    "geo": [
      9.73861,
      52.37444
    ]
  },
  {
    "id": "nuernberg",
    "city": "Nürnberg",
    "population": "518.370",
    "geo": [
      11.07778,
      49.45278
    ]
  },
  {
    "id": "duisburg",
    "city": "Duisburg",
    "population": "498.686",
    "geo": [
      6.76111,
      51.43222
    ]
  },
  {
    "id": "bochum",
    "city": "Bochum",
    "population": "365.587",
    "geo": [
      7.21667,
      51.48333
    ]
  },
  {
    "id": "wuppertal",
    "city": "Wuppertal",
    "population": "355.100",
    "geo": [
      7.18333,
      51.26667
    ]
  },
  {
    "id": "bielefeld",
    "city": "Bielefeld",
    "population": "334.195",
    "geo": [
      8.51667,
      52.01667
    ]
  },
  {
    "id": "bonn",
    "city": "Bonn",
    "population": "329.673",
    "geo": [
      7.1002,
      50.73616
    ]
  },
  {
    "id": "muenster",
    "city": "Münster",
    "population": "315.293",
    "geo": [
      7.63333,
      51.96667
    ]
  },
  {
    "id": "karlsruhe",
    "city": "Karlsruhe",
    "population": "312.060",
    "geo": [
      8.40417,
      49.01389
    ]
  },
  {
    "id": "mannheim",
    "city": "Mannheim",
    "population": "310.658",
    "geo": [
      8.46306,
      49.48361
    ]
  },
  {
    "id": "augsburg",
    "city": "Augsburg",
    "population": "296.582",
    "geo": [
      10.9313665,
      48.4246125
    ]
  },
  {
    "id": "wiesbaden",
    "city": "Wiesbaden",
    "population": "278.474",
    "geo": [
      8.24136,
      50.08208
    ]
  },
  {
    "id": "moenchengladbach",
    "city": "Mönchengladbach",
    "population": "261.034",
    "geo": [
      6.43333,
      51.2
    ]
  },
  {
    "id": "gelsenkirchen",
    "city": "Gelsenkirchen",
    "population": "259.645",
    "geo": [
      7.1,
      51.51667
    ]
  },
  {
    "id": "braunschweig",
    "city": "Braunschweig",
    "population": "249.406",
    "geo": [
      10.52333,
      52.26471
    ]
  },
  {
    "id": "aachen",
    "city": "Aachen",
    "population": "248.960",
    "geo": [
      6.0825,
      50.77417
    ]
  },
  {
    "id": "kiel",
    "city": "Kiel",
    "population": "246.794",
    "geo": [
      10.14056,
      54.32528
    ]
  },
  {
    "id": "chemnitz",
    "city": "Chemnitz",
    "population": "246.334",
    "geo": [
      12.91667,
      50.83333
    ]
  },
  {
    "id": "halle_saale",
    "city": "Halle (Saale)",
    "population": "238.762",
    "geo": [
      11.97194,
      51.48306
    ]
  },
  {
    "id": "magdeburg",
    "city": "Magdeburg",
    "population": "237.565",
    "geo": [
      11.61667,
      52.13333
    ]
  },
  {
    "id": "freiburg_im_breisgau",
    "city": "Freiburg im Breisgau",
    "population": "231.195",
    "geo": [
      7.84972,
      47.99472
    ]
  },
  {
    "id": "krefeld",
    "city": "Krefeld",
    "population": "227.417",
    "geo": [
      6.56667,
      51.33333
    ]
  },
  {
    "id": "mainz",
    "city": "Mainz",
    "population": "218.578",
    "geo": [
      8.26667,
      50
    ]
  },
  {
    "id": "luebeck",
    "city": "Lübeck",
    "population": "216.530",
    "geo": [
      10.68639,
      53.86972
    ]
  },
  {
    "id": "erfurt",
    "city": "Erfurt",
    "population": "213.981",
    "geo": [
      11.02917,
      50.97806
    ]
  },
  {
    "id": "oberhausen",
    "city": "Oberhausen",
    "population": "210.764",
    "geo": [
      6.85,
      51.55
    ]
  },
  {
    "id": "rostock",
    "city": "Rostock",
    "population": "209.191",
    "geo": [
      12.13333,
      54.08333
    ]
  },
  {
    "id": "kassel",
    "city": "Kassel",
    "population": "202.137",
    "geo": [
      9.49778,
      51.31972
    ]
  },
  {
    "id": "hagen",
    "city": "Hagen",
    "population": "188.686",
    "geo": [
      7.475,
      51.35944
    ]
  },
  {
    "id": "saarbruecken",
    "city": "Saarbrücken",
    "population": "180.374",
    "geo": [
      7.111228499999999,
      49.2168665
    ]
  },
  {
    "id": "potsdam",
    "city": "Potsdam",
    "population": "180.334",
    "geo": [
      -74.9813,
      44.6698
    ]
  },
  {
    "id": "hamm",
    "city": "Hamm",
    "population": "179.916",
    "geo": [
      -90.4612,
      30.5044
    ]
  },
  {
    "id": "ludwigshafen_am_rhein",
    "city": "Ludwigshafen am Rhein",
    "population": "172.253",
    "geo": [
      8.43528,
      49.48111
    ]
  },
  {
    "id": "muelheim_an_der_ruhr",
    "city": "Mülheim an der Ruhr",
    "population": "170.632",
    "geo": [
      6.87889,
      51.42833
    ]
  },
  {
    "id": "oldenburg_oldb",
    "city": "Oldenburg (Oldb)",
    "population": "169.077",
    "geo": [
      8.222658,
      53.1438275
    ]
  },
  {
    "id": "osnabrueck",
    "city": "Osnabrück",
    "population": "165.251",
    "geo": [
      8.04306,
      52.27889
    ]
  },
  {
    "id": "leverkusen",
    "city": "Leverkusen",
    "population": "163.729",
    "geo": [
      6.98333,
      51.03333
    ]
  },
  {
    "id": "heidelberg",
    "city": "Heidelberg",
    "population": "161.485",
    "geo": [
      8.71,
      49.41222
    ]
  },
  {
    "id": "darmstadt",
    "city": "Darmstadt",
    "population": "159.878",
    "geo": [
      8.64844,
      49.87186
    ]
  },
  {
    "id": "solingen",
    "city": "Solingen",
    "population": "159.245",
    "geo": [
      7.08333,
      51.16667
    ]
  },
  {
    "id": "herne",
    "city": "Herne",
    "population": "156.449",
    "geo": [
      -0.101,
      51.457
    ]
  },
  {
    "id": "neuss",
    "city": "Neuss",
    "population": "153.896",
    "geo": [
      6.69389,
      51.20028
    ]
  },
  {
    "id": "regensburg",
    "city": "Regensburg",
    "population": "153.094",
    "geo": [
      12.08333,
      49.01667
    ]
  },
  {
    "id": "paderborn",
    "city": "Paderborn",
    "population": "151.633",
    "geo": [
      8.76667,
      51.71667
    ]
  },
  {
    "id": "ingolstadt",
    "city": "Ingolstadt",
    "population": "137.392",
    "geo": [
      11.42611,
      48.76361
    ]
  },
  {
    "id": "offenbach_am_main",
    "city": "Offenbach am Main",
    "population": "130.280",
    "geo": [
      8.76667,
      50.1
    ]
  },
  {
    "id": "fuerth",
    "city": "Fürth",
    "population": "128.497",
    "geo": [
      10.99028,
      49.47833
    ]
  },
  {
    "id": "wuerzburg",
    "city": "Würzburg",
    "population": "127.934",
    "geo": [
      9.92944,
      49.79444
    ]
  },
  {
    "id": "ulm",
    "city": "Ulm",
    "population": "126.790",
    "geo": [
      -94.5015235,
      44.32127
    ]
  },
  {
    "id": "heilbronn",
    "city": "Heilbronn",
    "population": "126.592",
    "geo": [
      9.21667,
      49.15
    ]
  },
  {
    "id": "pforzheim",
    "city": "Pforzheim",
    "population": "125.957",
    "geo": [
      8.71667,
      48.9
    ]
  },
  {
    "id": "wolfsburg",
    "city": "Wolfsburg",
    "population": "124.371",
    "geo": [
      10.78722,
      52.42306
    ]
  },
  {
    "id": "goettingen",
    "city": "Göttingen",
    "population": "118.911",
    "geo": [
      9.93556,
      51.53389
    ]
  },
  {
    "id": "bottrop",
    "city": "Bottrop",
    "population": "117.565",
    "geo": [
      6.92527,
      51.52325
    ]
  },
  {
    "id": "reutlingen",
    "city": "Reutlingen",
    "population": "115.865",
    "geo": [
      9.21667,
      48.48333
    ]
  },
  {
    "id": "koblenz",
    "city": "Koblenz",
    "population": "114.052",
    "geo": [
      7.59778,
      50.35972
    ]
  },
  {
    "id": "bremerhaven",
    "city": "Bremerhaven",
    "population": "113.643",
    "geo": [
      8.5933,
      53.5545
    ]
  },
  {
    "id": "erlangen",
    "city": "Erlangen",
    "population": "112.528",
    "geo": [
      11.00444,
      49.59639
    ]
  },
  {
    "id": "bergisch_gladbach",
    "city": "Bergisch Gladbach",
    "population": "111.846",
    "geo": [
      7.13667,
      50.99167
    ]
  },
  {
    "id": "trier",
    "city": "Trier",
    "population": "111.528",
    "geo": [
      6.64139,
      49.75667
    ]
  },
  {
    "id": "recklinghausen",
    "city": "Recklinghausen",
    "population": "111.397",
    "geo": [
      7.1615,
      51.607
    ]
  },
  {
    "id": "jena",
    "city": "Jena",
    "population": "111.343",
    "geo": [
      11.58639,
      50.92722
    ]
  },
  {
    "id": "remscheid",
    "city": "Remscheid",
    "population": "111.338",
    "geo": [
      7.2,
      51.18333
    ]
  },
  {
    "id": "salzgitter",
    "city": "Salzgitter",
    "population": "104.291",
    "geo": [
      10.40585,
      52.15366
    ]
  },
  {
    "id": "moers",
    "city": "Moers",
    "population": "103.902",
    "geo": [
      6.61972,
      51.45917
    ]
  },
  {
    "id": "siegen",
    "city": "Siegen",
    "population": "102.770",
    "geo": [
      8.01667,
      50.87556
    ]
  },
  {
    "id": "hildesheim",
    "city": "Hildesheim",
    "population": "101.693",
    "geo": [
      9.95,
      52.15
    ]
  },
  {
    "id": "guetersloh",
    "city": "Gütersloh",
    "population": "100.861",
    "geo": [
      8.38333,
      51.9
    ]
  },
  {
    "id": "kaiserslautern",
    "city": "Kaiserslautern",
    "population": "100.030",
    "geo": [
      7.76889,
      49.44472
    ]
  }
]
