import React, {useEffect, useState} from 'react';
import mapboxgl from "mapbox-gl";
import ReactMapGL from 'react-map-gl';
import {topCities} from '../utils/data';
import NewsletterForm from '../components/newsletterForm';
import CheckGreen from '../assets/images/CheckGreen';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const City = (props) => {

  const [viewport, setViewport] = useState({
    latitude: 48.14185487956389,
    longitude: 11.57305881429826,
    zoom: 10,
  });

  const [activeCity, setActiveCity] = useState({
    id: '',
    city: '',
    geo: [0, 0]
  })

  useEffect(() => {
    topCities.filter(obj => {
      if(obj.city === props.id) {
        setActiveCity(obj)
        setViewport({
          ...viewport,
          latitude: obj.geo[1],
          longitude: obj.geo[0],
        })
      }
    })
  }, [props])

  return (
    <section className="city">

      <div className="city__searchbar">
      </div>

      <div className="city__container">

        <div className="city__map">
          <div className="city__mapbox">
            <ReactMapGL
              {...viewport}
              mapboxApiAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
              width="100%"
              height="100%"
              mapStyle="mapbox://styles/mapbox/streets-v11"
              onViewportChange={(viewport) => setViewport(viewport)}
            />
          </div>
        </div>

        <div className="city__content">
          <h2 className="city__content-title">Danke für deine Stimme!</h2>

          <div className="mt-4 mb-12 flex items-center">
            Ein Vote mehr für { activeCity.city } &nbsp; <CheckGreen />
          </div>

          <div className="max-w-500">
            <NewsletterForm
              headline={'Erfahre sobald MyFlexHome in '+activeCity.city+' verfügbar ist'}
              description={'Melde dich für den MyFlexHome Newsletter an.'}
              source={'city'}
              city={props.id}
              showBetaInput={true}
            />
          </div>


        </div>

      </div>
    </section>
  )
}

export default City
