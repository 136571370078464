import React from 'react';
import { Router } from "@reach/router"
import {graphql, useStaticQuery} from 'gatsby';
import Layout from '../components/layout';
import City from '../sections/city';


const Stadt = ({location}) => {

  const data = useStaticQuery(graphql`
        query {
            contentfulLayout(title: {eq: "Homepage"}) {
                id
                title
                description
                contentful_id,
                menu {
                    name
                    type
                    menuItems {
                        id
                        title
                        url
                    }
                },
                contentModule {
                    ... on Node {
                        id
                    }
                }
            }
        }
    `);
  const menus = data.contentfulLayout.menu;

  return (
    <Layout menus={ menus }>
      <Router>
        <City path="/stadt/:id" state={location.state}/>
      </Router>
    </Layout>
  )

}

export default Stadt
